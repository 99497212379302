<template>
  <CModal size="lg" :show.sync="AddModal" :centered="true">
    <form-wizard
      ref="NewProdWiz"
      :title="$t('wizard.newproduct.title')"
      subtitle=""
      :nextButtonText="$t('common.wizard.next')"
      :backButtonText="$t('common.wizard.back')"
      :finishButtonText="$t('common.wizard.save')"
      color="#000"
      error-color="#e74c3c"
      @on-complete="onComplete"
      @on-loading="setLoading"
      @on-validate="handleValidation"
      @on-error="handleErrorMessage"
    >
      <tab-content
        :title="$t('wizard.newproduct.step1.title')"
        :before-change="beforeTabSwitch0"
      >
        <Formatter
          :config="new_step_0"
          :dataForm="dataForm"
          v-on="$listeners"
        />
      </tab-content>
      <tab-content
        :title="$t('wizard.newproduct.step2.title')"
        :before-change="beforeTabSwitch1"
      >
        <Formatter
          :config="new_step_1"
          :dataForm="dataForm"
          v-on="$listeners"
        />
      </tab-content>
      <tab-content
        :title="$t('wizard.newproduct.step3.title')"
        :before-change="beforeTabSwitch2"
      >
        <Formatter
          :config="new_step_2"
          :dataForm="dataForm"
          v-on="$listeners"
        />
      </tab-content>
      <tab-content
        :title="$t('wizard.newproduct.step4.title')"
        :before-change="beforeTabSwitch3"
      >
        <Formatter
          :config="new_step_3"
          :dataForm="dataForm"
          v-on="$listeners"
        />
      </tab-content>
      <div class="loading" v-if="loadingWizard">
        <CSpinner color="secondary" size="lg" />
      </div>
      <div v-if="errorMsg">
        <CAlert show color="danger">{{ errorMsg }}</CAlert>
      </div>
    </form-wizard>
    <template v-slot:header-wrapper>
      <span></span>
    </template>
    <template v-slot:footer-wrapper>
      <span></span>
    </template>
  </CModal>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import Formatter from "../../../common/form/Formatter";

import { GetDataManager } from "../../../ds/index";
import { Query, Predicate } from "@syncfusion/ej2-data";
import GetOptions from "../../../common/form/options";

function index(obj, is, value) {
  if (typeof is == "string") return index(obj, is.split("."), value);
  else if (is.length == 1 && value !== undefined) return (obj[is[0]] = value);
  else if (is.length == 0) return obj;
  else return index(obj[is[0]], is.slice(1), value);
}

export default {
  model: {
    prop: "AddModal",
    event: "change",
  },
  props: ["AddModal", "pp"],
  components: {
    FormWizard,
    TabContent,
    Formatter,
  },
  data() {
    return {
      units: [
        { value: 1, label: this.$t("models.lot.units.option1") },
        { value: 2, label: this.$t("models.lot.units.option2") },
        { value: 3, label: this.$t("models.lot.units.option3") },
        { value: 4, label: this.$t("models.lot.units.option4") },
        { value: 5, label: this.$t("models.lot.units.option5") },
        { value: 6, label: this.$t("models.lot.units.option6") },
      ],
      loadingWizard: false,
      errorMsg: null,
      need_nation_options: {},
      baseDataForm: {
        // Form is loading
        loading: false,
        // Edit Mode
        active: true,
        // Form errors
        errors: {},
        // Form original data
        dataOrig: {},
        formData: {
          prod_name: "",
          lot_name: "",
          availability_limitless: true,
          availability_over: false,
          availability_xd: false,
          pw: false,
          production_type_id: 1,
          tax_rate_id: 1,
          productproducer_id: 0,
          producer_id: this.$store.state.role.producer__id,
          category__macrocategory_id: 0,
          category__parent_id: 0,
          ageclass_id: 1,
          certifications: [],
          image: "",
          image_label: "",
        },
        cur_lang: this.$i18n.locale,
      },
    };
  },
  created() {
    GetOptions({
      dm: GetDataManager("structure_production"),
      query_cache: "structure_production",
      reducer: function (map, obj) {
        if (obj.need_nation) {
          map.push(obj.id);
        }
        return map;
      },
      reducer_cache: "structure_production_need_nation",
    }).then((e) => {
      this.need_nation_options = e;
    });
  },
  computed: {
    dataForm() {
      let res = this.baseDataForm;
      let translations = {};
      translations[this.$i18n.locale] = {};
      res.formData.translations = translations;
      return res;
    },
    need_country() {
      return this.need_nation_options.length > 0
        ? this.need_nation_options.includes(
            this.dataForm.formData.production_type_id
          )
        : false;
    },
    new_step_0() {
      let fields = {};

      fields.prod_name = {
        title: this.$t("models.productproducer.name"),
        description: this.$t("models.productproducer.name_info"),
        type: "CInput",
        size: 12,
        subsize: [3, 9],
      };

      fields.category__macrocategory_id = {
        title: this.$t("models.productproducer.category__macrocategory_id"),
        description: this.$t(
          "models.productproducer.category__macrocategory_id_info"
        ),
        type: "CSelect",
        size: 12,
        subsize: [3, 9],
        options: GetOptions({
          dm: GetDataManager("structure_ptype"),
          query_cache: "structure_ptype",
          cur_lang: this.$i18n.locale,
        }),
      };
      fields.category__parent_id = {
        title: this.$t("models.productproducer.category__parent_id"),
        description: this.$t("models.productproducer.category__parent_id_info"),
        type: "CSelect",
        size: 12,
        subsize: [3, 9],
        options: GetOptions({
          dm: GetDataManager("structure_category_browser"),
          query: new Query().where(
            new Predicate("parent_id", "equal", null).and(
              "macrocategory_id",
              "equal",
              this.dataForm.formData.category__macrocategory_id
            )
          ),
          query_cache:
            "structure_category_browser_M" +
            this.dataForm.formData.category__macrocategory_id,
          cur_lang: this.$i18n.locale,
        }),
      };
      fields.category_id = {
        title: this.$t("models.productproducer.category_id"),
        description: this.$t("models.productproducer.category_id_info"),
        type: "CSelect",
        size: 12,
        subsize: [3, 9],
        options: GetOptions({
          dm: GetDataManager("structure_category_browser"),
          query: new Query().where(
            new Predicate(
              "parent_id",
              "equal",
              this.dataForm.formData.category__parent_id
            )
              .and(
                "macrocategory_id",
                "equal",
                this.dataForm.formData.category__macrocategory_id
              )
              .and("parent_id", "greaterthan", 0)
          ),
          query_cache:
            "structure_category_browser_M" +
            this.dataForm.formData.category__macrocategory_id +
            "_P" +
            this.dataForm.formData.category__parent_id,
          cur_lang: this.$i18n.locale,
        }),
      };
      fields.ptype_id = {
        title: this.$t("models.productproducer.ptype_id"),
        description: this.$t("models.productproducer.ptype_id_info"),
        type: "CSelect",
        size: 12,
        subsize: [3, 9],
        options: GetOptions({
          dm: GetDataManager("structure_ltype"),
          query: new Query().where(
            "macrocategory_id",
            "equal",
            this.dataForm.formData.category__macrocategory_id
          ),
          query_cache:
            "structure_ltype_" +
            this.dataForm.formData.category__macrocategory_id,
          cur_lang: this.$i18n.locale,
        }),
      };
      return {
        fields: fields,
      };
    },
    new_step_1() {
      let fields = {};

      fields.lot_name = {
        title: this.$t("models.lot.name"),
        description: this.$t("models.lot.name_info"),
        type: "CInput",
        size: 12,
        subsize: [3, 9],
      };
      fields.production_type_id = {
        title: this.$t("models.lot.production_type"),
        description: this.$t("models.lot.production_type_info"),
        type: "CSelect",
        size: 12,
        subsize: [3, 9],
        options: GetOptions({
          dm: GetDataManager("structure_production"),
          query_cache: "structure_production",
          cur_lang: this.$i18n.locale,
        }),
      };
      fields.production_type_nation_id = {
        title: this.$t("models.lot.production_type_nation"),
        description: this.$t("models.lot.production_type_nation_info"),
        type: "CSelect",
        size: 12,
        subsize: [3, 9],
        options: GetOptions({
          dm: GetDataManager("geo_country"),
          reducer: function (map, obj) {
            map.push({ value: obj.code, label: obj.name });
            return map;
          },
          reducer_cache: "geo_country",
        }),
        enabled: this.need_country,
      };
      return {
        fields: fields,
      };
    },
    new_step_2() {
      return {
        fields: {
          pw: {
            title: this.$t("models.lot.pw"),
            type: "CInputRadioGroup",
            description: this.$t("models.lot.pw_info"),
            size: 12,
            subsize: [3, 9],
            options: [
              { value: false, label: this.$t("models.lot.pw_optionF") },
              { value: true, label: this.$t("models.lot.pw_optionT") },
            ],
          },
          price: {
            title: this.$t("models.lot.price"),
            description: this.$t("models.lot.price_info"),
            type: "CNumber",
            size: 12,
            subsize: [3, 9],
          },
          unit: {
            title: this.$t("models.lot.unit"),
            description: this.$t("models.lot.unit_info"),
            size: 12,
            subsize: [3, 9],
            hide: true,
            children: {
              price_len: {
                type: "CNumber",
                size: 3,
                subsize: [0, 12],
              },
              price_unit: {
                type: "CSelect",
                size: 4,
                options: this.units,
                subsize: [0, 12],
              },
              price_len_mol: {
                label: this.$t("models.lot.unit_mol"),
                type: "CInputCheckboxB",
                size: 3,
                subsize: [0, 12],
                enabled: !this.dataForm.formData.pw,
              },
            },
          },
          pw_m_unit: {
            title: this.$t("models.lot.pw_m_unit"),
            description: this.$t("models.lot.pw_m_unit_info"),
            size: 12,
            subsize: [3, 9],
            hide: true,
            enabled: this.dataForm.formData.pw,
            children: {
              pw_len: {
                name: "pw_len",
                type: "CNumber",
                size: 3,
                subsize: [0, 12],
              },
              pw_unit: {
                name: "",
                type: "CSelect",
                size: 4,
                options: this.units,
                subsize: [0, 12],
              },
            },
          },
        },
      };
    },
    new_step_3() {
      return {
        fields: {
          availability_limitless: {
            title: this.$t("models.lot.w_availability_limitless"),
            type: "CInputRadioGroup",
            size: 12,
            subsize: [3, 9],
            options: [
              {
                value: true,
                label: this.$t("models.lot.w_availability_limitless_optionT"),
              },
              {
                value: false,
                label: this.$t("models.lot.w_availability_limitless_optionF"),
              },
            ],
            description: this.$t("models.lot.w_availability_limitless_info"),
          },
          availability_start: {
            title: this.$t("models.lot.w_availability_start"),
            description: this.$t("models.lot.w_availability_start_info"),
            type: "CNumber",
            size: 12,
            subsize: [3, 9],
            hide: true,
            enabled: !this.dataForm.formData.availability_limitless,
          },
          availability_xd: {
            title: this.$t("models.lot.w_availability_xd"),
            description: this.$t("models.lot.w_availability_xd_info"),
            type: "CInputRadioGroup",
            size: 12,
            subsize: [3, 9],
            options: [
              {
                value: true,
                label: this.$t("models.lot.w_availability_xd_optionT"),
              },
              {
                value: false,
                label: this.$t("models.lot.w_availability_xd_optionF"),
              },
            ],
            enabled: !this.dataForm.formData.availability_limitless,
          },
          availability_over: {
            title: this.$t("models.lot.w_availability_over"),
            type: "CInputRadioGroup",
            size: 12,
            subsize: [3, 9],
            options: [
              { value: true, label: this.$t("common.bool.true") },
              { value: false, label: this.$t("common.bool.false") },
            ],
            description: this.$t("models.lot.w_availability_over_info"),
            enabled: !this.dataForm.formData.availability_limitless,
          },
        },
      };
    },
  },
  methods: {
    setLoading: function (value) {
      this.loadingWizard = value;
    },
    handleValidation: function (isValid, tabIndex) {
      console.log("Tab: " + tabIndex + " valid: " + isValid);
    },
    handleErrorMessage: function (errorMsg) {
      this.errorMsg = errorMsg;
    },
    onComplete: function () {
      this.dataForm.loading = true;
      this.dataForm.errors = {};

      let params = Object.assign({}, this.dataForm.formData);
      params.translations[this.$i18n.locale].name = params.prod_name;
      Object.keys(params).forEach(function (key) {
        index(params, key, params[key]);
      });
      // params.productproducer_id = this.pp.id;
      //   'translations.it.name'.split('.').reduce((o,i)=>o[i], params)
      GetDataManager("role_producer_products", [this.$store.state.role.id])
        .insert(params)
        .then(
          (e) => {
            // console.log("after update");
            // console.log(e);
            // this.$emit("data_updated", e);
            // this.dataOrigOptions = JSON.parse(
            //   JSON.stringify(this.dataForm.formDataOptions)
            // );
            // let product_id = e.id;
            params.productproducer_id = e.id;
            params.translations[this.$i18n.locale].name = params.lot_name;
            GetDataManager("role_producer_products_lots", [
              this.$store.state.role.id,
            ])
              .insert(params)
              .then(
                (e) => {
                  this.baseDataForm.dataOrig = e;
                  // this.config.data = e
                  // this.AddModal=false
                  this.$emit("change", false);
                  this.$refs.NewProdWiz.reset();
                  this.dataForm.loading = false;
                  this.$store.dispatch("toaster/add", {
                    title: this.$t("wizard.newproduct.toast.add.title"),
                    text: this.$t("wizard.newproduct.toast.add.text"),
                    autohide: true,
                  });
                },
                (reason) => {
                  let errors = JSON.parse(reason[0].error.response);
                  this.dataForm.errors = errors;
                  this.dataForm.loading = false;
                }
              );
          },
          (reason) => {
            let errors = JSON.parse(reason[0].error.response);
            this.dataForm.errors = errors;
            this.dataForm.loading = false;
          }
        );
    },
    beforeTabSwitch0: function () {
      console.log("This is called before switching tabs 0");
      return this.validateProdData(this.new_step_0);
    },
    beforeTabSwitch1: function () {
      console.log("This is called before switching tabs 1");
      return this.validateData(this.new_step_1);
    },
    beforeTabSwitch2: function () {
      console.log("This is called before switching tabs 2");
      return this.validateData(this.new_step_2);
    },
    beforeTabSwitch3: function () {
      console.log("This is called before switching tabs 3");
      return this.validateData(this.new_step_3);
    },
    validateData: function (config) {
      console.log("Validate data");
      return new Promise((resolve, reject) => {
        this.dataForm.loading = true;
        this.dataForm.errors = {};

        let params = Object.assign({}, this.dataForm.formData);
        params.translations[this.$i18n.locale].name = params.lot_name;
        Object.keys(params).forEach(function (key) {
          index(params, key, params[key]);
        });
        // params.productproducer_id = this.pp.id;
        //   'translations.it.name'.split('.').reduce((o,i)=>o[i], params)
        GetDataManager("role_producer_products_lots_cdr", [
          this.$store.state.role.id,
        ])
          .insert(params)
          .then(
            () => {
              resolve(true);
            },
            (reason) => {
              let errors = JSON.parse(reason[0].error.response);
              this.dataForm.errors = errors;
              let valid = true;
              Object.keys(errors).forEach(function (key) {
                const transFields = Object.keys(config.fields).filter((field) =>
                  field.startsWith(key + ".")
                );
                const childFields = Object.keys(config.fields).reduce(function (
                  map,
                  fkey
                ) {
                  if (config.fields[fkey].children) {
                    map.push.apply(
                      map,
                      Object.keys(config.fields[fkey].children).reduce(
                        function (map, ffkey) {
                          map.push(ffkey);
                          return map;
                        },
                        []
                      )
                    );
                  }
                  return map;
                },
                []);
                console.log(key, childFields.includes(key), childFields);

                if (Object.keys(config.fields).includes(key)) {
                  valid = false;
                } else if (transFields.length > 0) {
                  valid = false;
                } else if (childFields.includes(key) > 0) {
                  valid = false;
                }
              });
              if (
                errors.translations &&
                errors.translations[this.$i18n.locale] &&
                errors.translations[this.$i18n.locale].name
              ) {
                valid = false;
                this.dataForm.errors.lot_name =
                  errors.translations[this.$i18n.locale].name;
              }
              if (valid == true) {
                this.dataForm.errors = {};
                resolve(true);
              } else {
                reject(this.$t("common.wizard.required"));
              }

              //   this.dataForm.loading = false;
            }
          );
        // setTimeout(() => {
        //   if (this.count < 1) {
        //     this.count++;
        //     reject(
        //       "This is a custom validation error message. Click next again to get rid of the validation"
        //     );
        //   } else {
        //     this.count = 0;
        //     resolve(true);
        //   }
        // }, 1000);
      });
    },
    validateProdData: function (config) {
      console.log("Validate data Prod");
      return new Promise((resolve, reject) => {
        this.dataForm.loading = true;
        this.dataForm.errors = {};

        let params = Object.assign({}, this.dataForm.formData);
        console.log(params);

        params.translations[this.$i18n.locale].name = params.prod_name;
        Object.keys(params).forEach(function (key) {
          index(params, key, params[key]);
        });
        // params.productproducer_id = this.pp.id;
        //   'translations.it.name'.split('.').reduce((o,i)=>o[i], params)
        console.log(params);
        GetDataManager("role_producer_products_cdr", [
          this.$store.state.role.id,
        ])
          .insert(params)
          .then(
            () => {
              resolve(true);
            },
            (reason) => {
              let errors = JSON.parse(reason[0].error.response);
              this.dataForm.errors = errors;
              let valid = true;

              Object.keys(errors).forEach(function (key) {
                const transFields = Object.keys(config.fields).filter((field) =>
                  field.startsWith(key + ".")
                );
                const childFields = Object.keys(config.fields).reduce(function (
                  map,
                  fkey
                ) {
                  if (config.fields[fkey].children) {
                    map.push.apply(
                      map,
                      Object.keys(config.fields[fkey].children).reduce(
                        function (map, ffkey) {
                          map.push(ffkey);
                          return map;
                        },
                        []
                      )
                    );
                  }
                  return map;
                },
                []);
                console.log(key, childFields.includes(key), childFields);

                if (Object.keys(config.fields).includes(key)) {
                  valid = false;
                } else if (transFields.length > 0) {
                  valid = false;
                } else if (childFields.includes(key) > 0) {
                  valid = false;
                }
              });
              if (
                errors.translations &&
                errors.translations[this.$i18n.locale] &&
                errors.translations[this.$i18n.locale].name
              ) {
                valid = false;
                this.dataForm.errors.prod_name =
                  errors.translations[this.$i18n.locale].name;
              }
              if (valid == true) {
                this.dataForm.errors = {};
                resolve(true);
              } else {
                reject(this.$t("common.wizard.required"));
              }

              //   this.dataForm.loading = false;
            }
          );
        // setTimeout(() => {
        //   if (this.count < 1) {
        //     this.count++;
        //     reject(
        //       "This is a custom validation error message. Click next again to get rid of the validation"
        //     );
        //   } else {
        //     this.count = 0;
        //     resolve(true);
        //   }
        // }, 1000);
      });
    },
  },
};
</script>
